var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dashboard"},[_c('div',{staticClass:"dashboard__container"},[_c('div',{staticClass:"dashboard__container--header"},[_c('h1',[_vm._v("Payroll Help")]),_c('span',[(!_vm.showAll)?_c('button',{staticClass:"btn btn__outlined",on:{"click":function($event){_vm.showAll = true}}},[_vm._v("Show Open")]):_vm._e(),(_vm.showAll)?_c('button',{staticClass:"btn btn__outlined",on:{"click":function($event){_vm.showAll = false}}},[_vm._v("Show Closed")]):_vm._e()])]),_c('div',{staticClass:"dashboard__container--body"},[(!_vm.payrollHelp || _vm.payrollHelp.length == 0)?_c('Loader'):_vm._e(),(_vm.showAll)?_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.openHelp,"styleClass":"vgt-table striped","search-options":{
            enabled: true,
            placeholder: 'Search this table',
          },"pagination-options":{
            enabled: true,
            mode: 'records',
            perPage: 20,
          }},on:{"on-row-click":_vm.onRowClick},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'startDate')?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(props.row.startDate,"MMM Do, YYYY"))+" "),(props.row.endDate)?_c('span',[_vm._v(" - "+_vm._s(_vm._f("moment")(props.row.endDate,"MMM Do, YYYY"))+" ")]):_vm._e()]):(props.column.field == 'complete')?_c('span',[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(props.row.closed),expression:"props.row.closed",modifiers:{"trim":true}}],staticClass:"ml-3",attrs:{"type":"checkbox","id":"check"},domProps:{"checked":Array.isArray(props.row.closed)?_vm._i(props.row.closed,null)>-1:(props.row.closed)},on:{"change":[function($event){var $$a=props.row.closed,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(props.row, "closed", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(props.row, "closed", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(props.row, "closed", $$c)}},function($event){return _vm.markAdded(props.row)}]}})]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}],null,false,3018978854)}):_vm._e(),(!_vm.showAll)?_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.closedHelp,"styleClass":"vgt-table striped","search-options":{
              enabled: true,
              placeholder: 'Search this table',
            },"pagination-options":{
              enabled: true,
              mode: 'records',
              perPage: 20,
            }},on:{"on-row-click":_vm.onRowClick},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'startDate')?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(props.row.startDate,"MMM Do, YYYY"))+" "),(props.row.endDate)?_c('span',[_vm._v(" - "+_vm._s(_vm._f("moment")(props.row.endDate,"MMM Do, YYYY"))+" ")]):_vm._e()]):(props.column.field == 'complete')?_c('span',[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(props.row.closed),expression:"props.row.closed",modifiers:{"trim":true}}],staticClass:"ml-3",attrs:{"type":"checkbox","id":"check"},domProps:{"checked":Array.isArray(props.row.closed)?_vm._i(props.row.closed,null)>-1:(props.row.closed)},on:{"change":[function($event){var $$a=props.row.closed,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(props.row, "closed", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(props.row, "closed", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(props.row, "closed", $$c)}},function($event){return _vm.markRemoved(props.row)}]}})]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}],null,false,1944869220)}):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }